<template>
  <div class="table">
    <header class="t-header">
      <div>
        <span>词条搜索：</span>
        <el-input v-model="params.word" placeholder="请输入词条(enter搜索)" @keyup.native.enter="getList"></el-input>
      </div>
      <div>
        <span>词库排序：</span>
        <el-select v-model="params.sort" placeholder="请选择排序" @change="getList">
          <el-option label="时间倒序" :value="1"></el-option>
          <el-option label="时间正序" :value="2"></el-option>
          <el-option label="词条A-Z排序" :value="3"></el-option>
          <el-option label="词条Z-A排序" :value="4"></el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="visible = true">批量导入</el-button>
      <el-button type="primary" @click="addVisible = true">添加词条</el-button>
      <!-- <el-button type="primary" @click="download">下载词库</el-button> -->
      <el-button type="primary" @click="$router.push('/risk/word_history?id='+$route.query.id)">删除记录</el-button>
      <el-button type="primary" @click="$router.back()">返回上页</el-button>
    </header>
    <DragTable :list-query="list" :header="header">
      <template #control="{ row }">
        <span class="word-color" @click="ctl(row)">删除</span>
      </template>
    </DragTable>
    <footer class="table-footer">
      <p></p>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer>
    <list-import :id="$route.query.state" :visible.sync="visible" ></list-import>
    <add-word :id="$route.query.id" :show.sync="addVisible"></add-word>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";
import ListImport from '../list_import.vue'
import addWord from '../add_word.vue'

export default defineComponent({
  components: { ListImport, addWord },
  setup(props, { root }) {
    const visible = ref(false)
    const addVisible = ref(false)
    const type = {
      '白名单': 1,
      '屏蔽词': 2,
      '多词组': 3,
      '高危词': 5,
      '正常词': 6,
    }
    watch(visible, val=>{
      if(!val) {
        getList()
      }
    })
    watch(addVisible, val=>{
      if(!val) {
        getList()
      }
    })
    const total = ref(10);
    const params = reactive({
      page: 1,
      limit: 10,
      sort: 1,
      word: undefined,
      dict_id: root.$route.query.id
    });
    let header = [
      { name: "序号", value: "id"},
      { name: "词条", value: "word"},
      { name: "词库类型", value: "type" },
      { name: "添加人", value: "operator" },
      { name: "添加日期", value: "update_at" },
      { name: "操作", slot: "control" }
    ];
    const list = reactive({ data: [] });
    const getList = () => {
      const url = "/rcp/dictionaryManage/commonDictionaryDetail/list"
      root.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          list.data = res.data.list;
          total.value = res.data.total;
        });
    };
    const clear = () => {
      for (let key in params) {
        params[key] = undefined;
      }
      params.page = 1;
      params.limit = 10;
      getList();
    };
    const ctl = async (data) =>{
      const confirm = await root.$goDialog("确定要删除吗？")
      if(!confirm) return
      root.$axios.post(`/rcp/dictionaryManage/dictionary/deleteDictionaryDetail`,{
        id: data.id,
        dic_type: type[data.type],
        operator: localStorage.getItem('userName')
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
      
    }
    const download = () => {
      if(list.data.length == 0) return
      var eleLink = document.createElement('a');
      eleLink.download = `${root.$route.query.state}表格${root.$dayjs().unix()}.csv`;
      eleLink.style.display = 'none';
      let content = `序号,词条,词库类型,添加人,添加日期\n`
      for(let i=0;i<list.data.length;i++) {
        content+= `${list.data[i].id},${list.data[i].word},${list.data[i].type},${list.data[i].operator},${list.data[i].update_at}\n`
      }
      var blob = new Blob([content],{type: 'text/csv,charset=UTF-8'});
      eleLink.href = URL.createObjectURL(blob);
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
    }
    const handleSizeChange = (size) => {
      params.limit = size;
      params.page = 1
      getList();
    };
    const handleCurrentChange = (index) => {
      params.page = index;
      getList();
    };
    getList();
    return {
      visible,
      addVisible,
      total,
      params,
      list,
      header,
      type,
      clear,
      getList,
      handleSizeChange,
      handleCurrentChange,
      ctl,
      download
    };
  },
});
</script>
<style lang="scss" scoped>
.table {
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
}
.t-header{
  display:flex;margin-bottom: 15px;align-items: center;
  div{
    display: flex;
    align-items: center;
    margin-right: 10px;
    span{
      white-space: nowrap;
    }
  }
}
.search-top {
  display: flex;
  margin-top: 15px;
  & > div {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    span {
      font-size: 14px;
      white-space: nowrap;
      text-align: right;
      flex-shrink: 0;
    }
    &:nth-of-type(2) {
      span {
        width: 70px;
      }
    }
    &:nth-of-type(3) {
      .input {
        width: 350px;
      }
    }
  }
}
.status {
  color: $safeColor;
  position: relative;
  padding-left: 13px;
  &.red {
    color: $dangerColor;
  }
}
.control {
  display: flex;
  span {
    margin-right: 14px;
    cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
    &.red{
      color: $dangerColor;
    }
    &.green{
      color: $safeColor;
    }
  }
}
.table-img {
  width: 100%;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
