<template>
<div class="wrap" v-if="visible" v-loading="loading">
  <div class="list-import">
    <p class="list-import-title">批量导入</p>
    <div class="list-import-btn-list">
      <el-button type="primary" @click="ctl(1)">下载词库模板</el-button>
      <div class="list-import-btn">
        <span>上传词库文档</span>
        <input ref="uploadFile" type="file" @change="getFile" />
      </div>
    </div>
    <p class="list-import-file-name">文件名称：{{fileName}}</p>
    <p class="prompt">批量导入将以添加的形式，请确认导入词库不含词库已有的词</p>
    <footer class="list-import-footer">
      <el-button type="primary" @click="ctl(2)">确认导入</el-button>
      <el-button type="primary" @click="ctl(3)">取消导入</el-button>
    </footer>
  </div>
</div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    visible: { type: Boolean, default: false },
  },
  setup(props,{ root, emit, refs }) {
    const loading = ref(false)
    const type = {
      '白名单': 1,
      '屏蔽词': 2,
      '多词组': 3,
      '高危词': 5,
      '正常词': 6,
    }
    const f = ref()
    const fileName = ref("")
    const getFile = e => {
      const files = e.target.files[0]
      f.value = files
      fileName.value = files.name
    }
    const ctl = e=>{
      if(e == 1) {
        root.$axios.get("/rcp/dictionaryManage/dictionary/downloadTemplate", {
          params:{
            type: type[root.$route.query.state]
          },
        })
        .then((res) => {
          if(res.code === 10000) {
            const a = window.document.createElement('a')
            a.href= root.$store.state.CMS.url+res.data.file_path
            a.click()
          } else {
            root.$message.error(res.msg)
          }
        })
        
      }
      if(e==2) {
        const dateForm = new FormData()
        dateForm.append("type", type[root.$route.query.state])
        dateForm.append("excel_file", f.value)
        loading.value = true
        root.$axios.post(`/rcp/dictionary/save`, dateForm)
        .then(res=>{
          root.$message.success(res.msg)
          refs.uploadFile.value = ''
          fileName.value = ""
          loading.value = false
          emit('update:visible',false)
        })
        .catch(err=>{
          loading.value = false
        })
      }
      if(e==3) {
        refs.uploadFile.value = ''
        fileName.value = ""
        emit('update:visible',false)
      }
    }
    return {
      fileName,
      getFile,
      ctl,
      loading
    }
  },
})
</script>
<style lang="scss" scoped>
.wrap{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 9;
}
.list-import{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
  box-shadow: 0 1px 5px #000;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  min-height: 100px;
}
.list-import-file-name{
  margin-top: 30px;
}
.list-import-footer{
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.list-import-btn-list{
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.list-import-btn{
  background-color: #4f5bff;
  height: 32px;
  line-height: 32px;
  width: 100px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  input{
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
  }
}
.prompt{
  margin-top: 10px;
  color: #333;
  font-size: 10px;
}
</style>