<template>
<div class="wrap" v-if="show">
    <div class="add-word">
      <h3>添加词条</h3>
      <textarea placeholder="请输入词条" v-model="word"></textarea>
      <footer class="add-word-footer">
        <el-button type="primary" @click="ctl">确认添加</el-button>
        <el-button type="primary" @click="cancel">取消添加</el-button>
      </footer>
    </div>
</div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    show: { type: Boolean, default: false },
    id: {  }
  },
  setup(props,{ root, emit }) {
    const word = ref("")
    const ctl = () => {
      if(!word.value || !word.value.trim()) {
        root.$message.warning("请输入词条")
        return
      }
      root.$axios.post(`/rcp/dictionaryManage/dictionary/addCommonDictionaryDetail`,{
        dict_id: props.id,
        word: word.value,
        operator: localStorage.getItem('userName')
      })
      .then(res=>{
        root.$message.success(res.msg)
        word.value = ''
        emit('update:show', false)
      })
      
    }
    const cancel = () => {
      word.value = ''
      emit('update:show', false)
    }
    return {
      word,
      ctl,
      cancel
    }
  },
})
</script>
<style lang="scss" scoped>
.wrap{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 9;
}
.add-word{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 350px;
  min-height: 100px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  h3{
    text-align: center;
  }
  textarea{
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-top: 15px;
    display: block;
  }
}
.add-word-footer{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
</style>