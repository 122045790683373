<template>
<div class="wrap" v-if="show">
    <div class="add-word">
      <h3>添加词条</h3>
      <textarea placeholder="请输入屏蔽词条" v-model="params.word"></textarea>
      <textarea placeholder="请输入条件词条1" v-model="params.first_related_word"></textarea>
      <textarea placeholder="请输入条件词条2" v-model="params.second_related_word"></textarea>
      <footer class="add-word-footer">
        <el-button type="primary" @click="ctl">确认添加</el-button>
        <el-button type="primary" @click="cancel">取消添加</el-button>
      </footer>
    </div>
</div>
</template>
<script>
import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  props: {
    show: { type: Boolean, default: false }
  },
  setup(props,{ root, emit }) {
    const params = reactive({
      dict_id: root.$route.query.id,
      operator: localStorage.getItem('userName'),
      word: undefined,
      first_related_word: undefined,
      second_related_word: undefined
    })
    const ctl = () => {
      if(!params.word || !params.word.trim()) {
        root.$message.warning("请输入词条")
        return
      }
      root.$axios.post(`/rcp/dictionaryManage/dictionary/addMultiDictionaryDetail`, params)
      .then(res=>{
        root.$message.success(res.msg)
        params.word = ''
        params.first_related_word = ''
        params.second_related_word = ''
        emit('update:show', false)
      })
      
    }
    const cancel = () => {
      params.word = ''
      params.first_related_word = ''
      params.second_related_word = ''
      emit('update:show', false)
    }
    return {
      params,
      ctl,
      cancel
    }
  },
})
</script>
<style lang="scss" scoped>
.wrap{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 9;
}
.add-word{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 350px;
  min-height: 100px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  h3{
    text-align: center;
  }
  textarea{
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-top: 15px;
    display: block;
  }
}
.add-word-footer{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
</style>