<template>
  <div class="table">
    <multiple v-if="flag" />
    <common v-else />
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import common from './components/detail/common_detail.vue'
import multiple from './components/detail/multiple_detail.vue'
export default defineComponent({
  components: { common, multiple },
  setup(props, { root }) {
    const flag = root.$route.query.state == "多词组"
    return {
      flag
    };
  },
});
</script>